<template>
  <div class="navbar-header p-0 m-0">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link class="navbar-brand d-flex align-items-center justify-content-center m-0" href="#hero">
          <span class="brand-logo">
            <b-img width="28px" :src="appLogoImage" alt="logo" />
          </span>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue';
import { $themeConfig } from '@themeConfig';

export default {
  components: {
    BLink,
    BImg,
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app;
    return {
      appName,
      appLogoImage,
    };
  },
};
</script>

<style>
html {
  scroll-behavior: smooth;
}

.navbar-header {
  display: flex;
  justify-content: center;
  width: 100%;
}

.navbar-brand {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
