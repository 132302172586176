<template>
  <div class="navbar-container content align-items-center w-100 py-25">
    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center d-none d-xl-flex">
      <sections />
    </div>

    <!-- Center Col -->
    <div class="navbar-brand-container cursor-pointer position-absolute w-100 d-flex justify-content-center p-0 m-0">
      <app-navbar-horizontal-layout-brand />
    </div>

    <!-- Right Col -->
    <div class="button-container d-flex align-items-center">
      <b-button v-if="!isFormPage" class="responsive-button" variant="gradient-primary p-75" :target="target" :href="ref" pill>
        <b>Demander un compte</b>
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import Sections from './components/Sections.vue';
import AppNavbarHorizontalLayoutBrand from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue';

export default {
  components: {
    Sections,
    AppNavbarHorizontalLayoutBrand,
    BButton,
  },
  watch: {
    '$route.name': {
      handler: function (routeName) {
        const isMainPage = routeName === "aqua-main"
        this.isFormPage = routeName === "aqua-form"

        this.ref = isMainPage ? '/join-form' : '#form'
        this.target = isMainPage ? '_blank' : ''
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    const isMainPage = this.$route.name === "aqua-main"
    const isFormPage = routeName === "aqua-form"

    const ref = isMainPage ? '/join-form' : '#form'
    const target = isMainPage ? '_blank' : ''

    return {
      ref,
      target,
      isFormPage,
    }
  },
  methods: {
  },
};
</script>

<style scoped>
.navbar-container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 15px;
  /* Adjust padding to prevent content from touching screen edges */
  box-sizing: border-box;
  position: relative;
}

.navbar-brand-container {
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  /* Ensure it is above other content but below the button */
  pointer-events: none;
  /* Make sure brand container does not block clicks */
}

.bookmark-wrapper {
  z-index: 2;
  /* Ensure it is above the brand container */
}

.button-container {
  margin-left: auto;
  z-index: 3;
  /* Ensure it is above the bookmark-wrapper and brand container */
}

.responsive-button {
  max-width: 200px;
  /* Set a max-width for larger screens */
}

@media (max-width: 767px) {
  .responsive-button {
    max-width: 150px;
    /* Reduce max-width for smaller screens */
    font-size: 0.75rem;
    /* Adjust font size */
    padding: 0.5rem 1rem;
    /* Adjust padding */
  }
}
</style>
