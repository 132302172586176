<template>
  <b-navbar-nav class="nav">
    <ul class="nav-list">
      <li v-for="(section, index) in sections" :id="`section-${index}`" :key="index" class="nav-item">
        <b-link :href="section.ref">
          <span>
            <p class="text-primary lead" style="font-weight: 500;">{{ section.name }}</p>
          </span>
        </b-link>
      </li>
    </ul>
  </b-navbar-nav>
</template>

<script>
import { BNavbarNav, BLink } from 'bootstrap-vue';

export default {
  components: {
    BNavbarNav,
    BLink,
  },
  watch: {
    '$route.name': {
      handler: function (routeName) {
        const isMainPage = routeName === "aqua-main"
        const isFormPage = routeName === "aqua-form"
        if (isMainPage) {
          this.sections = [
            { name: 'Accueil', ref: '#hero' },
            { name: 'Avantages', ref: '#advantages' },
            { name: 'Témoignages', ref: '#testimonials' },
            { name: 'Specialités', ref: '#specialties' },
            { name: 'FAQ', ref: '#faq' },
          ]
        } else if (isFormPage) {
          this.sections = [
            { name: 'AquaMedico', ref: '/' },
          ]
        } else {
          this.sections = [
            { name: 'Accueil', ref: '#hero' },
            { name: 'Fonctionalités', ref: '#functionalities' },
            { name: 'Nous rejoindre', ref: '#form' },
          ]
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    const isMainPage = this.$route.name === "aqua-main"
    let sections = [];
    if (isMainPage) {
      sections = [
        { name: 'Accueil', ref: '#hero' },
        { name: 'Avantages', ref: '#advantages' },
        { name: 'Témoignages', ref: '#testimonials' },
        { name: 'Specialités', ref: '#specialties' },
        { name: 'FAQ', ref: '#faq' },
      ]
    } else {
      sections = [
        { name: 'AquaMedico', ref: '/' },
        { name: 'Accueil', ref: '#hero' },
        { name: 'Fonctionalités', ref: '#functionalities' },
        { name: 'FAQ', ref: '#faq' },]
    }

    return {
      sections,
    };
  },
};
</script>


<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

html {
  scroll-behavior: smooth;
}

.nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  /* or whatever layout you need */
}

.nav-item {
  cursor: pointer;
  margin-right: 1.2rem;
  /* Adjust spacing as needed */

  a {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    /* Ensure it inherits the color from the parent */
  }

  p {
    margin: 0;
  }
}

.nav-bookmar-content-overlay {
  position: fixed;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.7s;
  z-index: -1;

  &:not(.show) {
    pointer-events: none;
  }

  &.show {
    cursor: pointer;
    z-index: 10;
    opacity: 1;
  }
}
</style>
